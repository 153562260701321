@import '@responsive'
@import '@mixins'
@import '@fonts'

.container
  display: flex
  flex-direction: column
  position: fixed
  top: 0
  left: 0
  right: 0
  height: 100vh
  background-color: #e5e5e5

  & canvas
    transition: filter 0.35s ease-in-out
    -webkit-transition: -webkit-filter 0.35s ease-in-out

  &[data-map-loaded=false] canvas
    filter: grayscale(100%)
    -webkit-filter: grayscale(100%)

  &[data-map-loaded=true] canvas
    filter: grayscale(0%)
    -webkit-filter: grayscale(0%)

.header-wrapper
  background-color: #fff

.content-desktop
  display: flex
  justify-content: space-between
  position: relative
  height: calc(100% - 80px)
  z-index: 1
  flex: 1

  .button-close
    position: absolute
    top: 0
    right: 0

  .map:after
    content: ''
    display: block
    position: absolute
    left: 0
    right: 0
    top: 0
    pointer-events: none
    height: 162px
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%)
    z-index: 0


.controls
  position: relative
  margin-top: 20px
  margin-right: 20px


.zoom-and-location
  position: absolute
  top: calc(50% - 177px/2)
  right: 0
  display: flex
  flex-direction: column
  gap: 8px

.aside
  padding: 16px 0 0 17px

.map
  position: absolute
  top: 0
  left: 0
  z-index: 0
  width: 100%
  height: 100%


.content-mobile
  position: relative
  height: 100dvh
  padding: 30px 12px 12px 12px
  display: flex
  flex-direction: column

  justify-content: space-between
  z-index: 1
  &:after
    content: ''
    display: block
    position: absolute
    left: 0
    right: 0
    top: 0
    pointer-events: none
    height: 130px
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.4) 49.48%, rgba(255, 255, 255, 0) 100%)
    z-index: 0

  .header
    display: flex
    align-items: flex-start
    gap: 12px
    justify-content: space-between

  .title
    position: relative
    z-index: 1
    max-width: 295px
    text-wrap: initial

  .button-close
    position: relative
    z-index: 1

  .footer
    align-self: center
    z-index: 1
    max-width: calc(100vw - 40px)
    width: 100%

.footer-buttons
  display: flex
  flex-direction: column
  gap: 12px
  margin-bottom: 12px

.footer-button-location
  display: flex
  justify-content: flex-end

.loading
  min-height: initial
  position: absolute
  top: 80px
  right: 17px
  left: initial
