@import "@responsive"

$side: 8px
$width: 100px
$marquee-area-width: calc($side + $width + $side)

@keyframes slide-first
  0%
    transform: translateX(0)
  100%
    transform: translateX(calc(-100% - $side - $side))

@keyframes slide-loop
  0%
    transform: translateX($marquee-area-width)
  100%
    transform: translateX(calc(-100% - $side - $side))

.city-selected[data-marquee=true]
  position: relative
  overflow: hidden
  --side: 8px
  margin-left: calc($side * -1)
  margin-right: calc($side * -1)
  padding-left: $side
  padding-right: $side

  &::before,
  &::after
    content: ''
    position: absolute
    top: 0
    bottom: 0
    width: $side
    z-index: 1

  &::before
    left: 0
    background: linear-gradient(90deg, white, transparent)

  &::after
    right: 0
    background: linear-gradient(270deg, white, transparent)

  & > .city-text
    display: inline-block
    --delay: 2s
    --duration: 15s
    position: relative
    animation: slide-first 15s linear 2s forwards, slide-loop 15s linear calc(2s + 15s) infinite
    // --loop-count: 3
    // animation: slide-first var(--duration) linear var(--delay) forwards, slide-loop var(--duration) linear calc(var(--delay) + var(--duration)) var(--loop-count), slide-last 2s linear calc(var(--duration) + var(--delay) * var(--loop-count)) forwards

    span:last-child
      display: inline-block
      margin-left: calc($marquee-area-width / 2)

.city-selected
  text-align: left
  font-weight: 400
  font-size: 13px
  line-height: 18px
  text-transform: uppercase
  transition: color .15s ease
  white-space: nowrap
  +responsive(mobile)
    font-size: 12px
    line-height: 16px
    letter-spacing: 0.12px
  &:hover
    color: var(--c-afisha)

.popup-city-select-wrapper
  width: 420px

.popup-city-select-content
  display: flex
  flex-direction: column
  gap: 20px
  max-width: 380px
  +responsive(mobile)
    max-width: initial

.popup-city-check
  padding: 24px
  min-width: 360px
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.20)
  +responsive(mobile)
    min-width: initial
    width: calc(100vw - 24px)
    padding: 16px

  :global(.popup-button-close)
    top: 15px
    +responsive(mobile)
      top: 7px

  :global(.popup-title)
    padding-right: 35px


.popup-city-check-content
  display: flex
  justify-content: space-between
  gap: 6px
  & > *
    flex: 1

.popular-cities-wrapper
  position: relative
  margin-left: -15px
  padding-left: 15px
  overflow: hidden
  &:after,
  &:before
    pointer-events: none
    display: block
    content: ''
    position: absolute
    top: 0
    height: 100%
    width: 40px
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)
    z-index: 1
  &:before
    left: -1px
    width: 20px
    transform: rotate(180deg)
  &:after
    right: -1px

.popular-cities
  overflow: visible !important

.button-city-slide
  button
    white-space: nowrap

.separator
  border: none
  height: 1px
  margin: 0
  background-color: #E1E1E1
  max-width: initial

.button-close-desktop
  padding: 3px

.modal
  height: 100vh

.content
  max-height: 337px
  overflow: auto
  position: relative
  margin: -15px 0 -10px 0
  padding: 15px 0 0 0
  &::-webkit-scrollbar
    width: 0

  +responsive(mobile)
    max-height: calc(100vh - 245px)

.content-shadow
  position: fixed
  height: 0
  left: 10px
  right: 10px
  pointer-events: none
  z-index: 1
  &:after
    display: block
    content: ''
    position: absolute
    left: 0
    right: 0
  &.top:after
    top: -15px
    height: 25px
    background: linear-gradient(180deg, #FFFFFF 0%, transparent 100%)
  &.bottom
    bottom: 0
    &:after
      bottom: 10px
      height: 25px
      background: linear-gradient(0deg, #FFFFFF 0%, transparent 100%)

.groups-list,
.groups-element
  display: flex
  flex-direction: column
  gap: 8px

.search-city-list,
.groups-list
  margin-bottom: 12px

.city-element
  +responsive(mobile)
    border-bottom: 1px solid #EBEBEB
    &:last-child
      border-bottom: none

.city-element-link
  transition: color .2s ease
  cursor: pointer
  width: 100%
  height: 100%
  display: block
  padding-top: 8px
  padding-bottom: 8px
  line-height: 20px
  +responsive(mobile)
    padding-top: 0
    padding-bottom: 0
    height: 52px
    line-height: 52px
  &:hover
    color: var(--c-afisha)

.nothing-found
  +responsive(mobile)
    display: flex
    justify-content: center
    align-items: center
    height: calc(100vh - 280px)
