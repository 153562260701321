@import '@responsive'
@import '@mixins'
@import '@fonts'

@keyframes animation-loader
  0%
    opacity: .5
  100%
    opacity: 0


.wrapper
  height: 100%
  display: flex
  align-items: flex-start
  gap: 16px


.grid-container
  position: relative
  height: 100%
  z-index: 0
  margin-right: 45px
  max-width: 546px

.grid
  position: relative
  display: flex
  flex-direction: column
  padding: 16px 20px
  gap: 20px
  height: calc(100% + 24px)
  background-color: #fff
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15)
  border-radius: 24px


.title
  z-index: 2
  max-width: 470px
  text-wrap: initial

.list-wrapper
  position: relative
  height: 100%

.list
  width: 506px
  height: 100%
  overflow: auto
  padding-top: 16px
  transform: translateY(-16px)
  padding-bottom: 250px
  &.list-empty
    padding-bottom: initial

  &::-webkit-scrollbar
    display: none

  &:after
    content: ''
    position: absolute
    display: block
    width: 100%
    height: 100%
    top: 0
    left: 0
    pointer-events: none
    opacity: 0
    background-color: #fff
    transition: opacity .5s ease 0.5s
    z-index: 1
  &[data-loading=true]:after
    opacity: 0.7
    transition: opacity 0.1s ease 0s
    pointer-events: initial

.list-inner
  display: flex
  flex-wrap: wrap
  align-content: flex-start
  gap: 32px 0
  justify-content: space-between
  width: 100%


.gradient
  display: block
  position: absolute
  left: -10px
  right: -10px
  top: -17px
  height: 24px
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.9) 20%, rgba(255, 255, 255, 0) 40%)
  z-index: 1
  &[data-visible=true]
    transition: all .2s ease
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.9) 20%, rgba(255, 255, 255, 0) 100%)

.icon svg
  transform: rotate(90deg)

#button-open-grid
  opacity: 0
  padding: 10px 12px 10px 20px
  margin-top: 16px
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)
  border: none
  & > div
    display: flex
    align-items: center
    gap: 6px
    svg
      transform: rotate(-90deg)
      width: 18px
      height: 20px


.not-found
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  text-align: center
  margin-bottom: 80px
  &__text
    margin-top: 16px


/* Анимации для CSSTransition */
.fade-enter
  position: absolute
  top: 16px
  left: 0
  opacity: 0
  //transform: translateX(100%)


.fade-enter-active
  position: relative
  top: 0
  opacity: 1
  //transform: translateX(0%)
  transition: opacity 0.4s ease


.fade-exit
  position: absolute
  top: 16px
  left: 0
  opacity: 1
  transform: translateX(0%)


.fade-exit-active
  position: absolute
  top: 16px
  left: 0
  opacity: 0
  //transform: translateX(-100%)
  transition: opacity 0.4s ease


/* Анимации для CSSTransition */
.translate-enter
  opacity: 0.5
  transform: translateX(-120%)

.translate-enter-active
  opacity: 1
  transform: translateX(0%)
  transition: all 0.3s ease

.translate-exit
  opacity: 1
  transform: translateX(0%)

.translate-exit-active
  opacity: 0.5
  transform: translateX(-120%)
  transition: all 0.3s ease


/* Анимации для CSSTransition */
.button-animation-enter-active
  position: absolute
  opacity: 0

.button-animation-enter-done
  opacity: 1 !important
  transition: all 0.2s ease 0.3s

.button-animation-exit
  position: absolute
  opacity: 0

.button-animation-exit-active
  opacity: 0
