@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  position: relative

#button
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)
  svg
    opacity: 1
    transition: opacity .3s ease
  &[data-loading=true]
    svg
      opacity: 0.08

.spinner
  position: absolute
  top: 0
  right: 0
