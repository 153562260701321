@import '@responsive'
@import '@mixins'
@import '@fonts'

.slider
  position: relative
  min-height: 141px
  opacity: 1
  transition: opacity .4s ease
  &:global(.swiper)
    overflow: visible
  :global(.swiper-wrapper)
    min-height: 133px
    transition-timing-function: cubic-bezier(0.38, 0.28, 0.12, 1.30)
    align-items: center !important
  &[data-loading=true]
    pointer-events: none
    opacity: 0

.skeleton,
.not-found
  position: absolute
  bottom: 12px
  width: 100%
  right: calc(100% - 52px)
  display: flex
  gap: 8px
  align-items: center
  z-index: 10
  opacity: 0
  transition: opacity .4s ease
  pointer-events: none
  &[data-show=true]
    opacity: 1




