@import '@responsive'
@import '@mixins'
@import '@fonts'

.adv-header
  max-width: initial
  transition: all .2s ease
  background-color: var(--c-gray-95)
  &[data-has-adv=false]
    height: 0
  &[data-has-adv=true]
    height: 120px

.adv-wrapper
  display: flex
  justify-content: center
  position: relative
  top: 0
  left: 0
  right: 0
  z-index: 1
  height: inherit
  padding: 0

.adv-container
  margin: 0 0 0
  width: 100%
  display: flex
  justify-content: center
  &.adv-container--dev
    box-shadow: inset 0 0 0 4px salmon
  &.adv-container--mobile
    margin: 0 0 0
  &.adv-container--picnic
    margin: 0 0 0

.adv-place
  width: 100%
  height: 100%
  min-height: 120px
  // margin-bottom: 25px
  // &.adv-place--mobile
    // margin-bottom: 15px
    // min-height: 120px

.plug
  background-color: #fff
  position: absolute
  top: 0
  left: 0
  right: 0
  // margin-bottom: 20px
