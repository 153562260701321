@import '@responsive'
@import '@mixins'
@import '@fonts'

@keyframes show
  0%
    transform: scale(0.5)
    opacity: 0
  70%
    opacity: 1
  100%
    opacity: 1
    transform: scale(1)

.label
  display: flex
  flex-direction: column
  gap: 6px
  max-width: 300px
  min-height: 68px
  padding: 12px
  border-radius: 16px
  background-color: #fff
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1)
  transform-origin: 12px 12px
  animation: show .15s ease
  cursor: pointer

.header,
.footer
  display: flex
  gap: 8px
  align-items: center

.icon
  width: 20px
  height: 20px
  border: 2px solid #fff
  border-radius: 50%
  &[data-color='green']
    background-color: rgba(49, 190, 0, 1)
  &[data-color='green-light']
    background-color: rgba(49, 190, 0, 0.4)

.title
  font-size: 15px
  font-weight: 400
  line-height: 20px
  cursor: pointer

.dot
  width: 4px
  height: 4px
  border-radius: 50%
  background-color: rgba(150, 150, 150, 1)

// rating

.rating
  font-size: 13px
  font-weight: 400
  line-height: 20px

  &[data-color='green']
    color: #31BE00
  &[data-color='gray']
    color: rgba(178, 178, 178, 0.40)
  &[data-color='red']
    color: #FF3118


// price

.price-range-wrap
  position: relative
  font-weight: 400 // 400
  font-size: 13px // 13px
  line-height: 18px // 18px
  display: inline-flex
  &--black span
    color: #969696
    &[data-active=true]
      color: var(--c-black)
  &.link
    cursor: pointer
    span
      transition: all .2s ease


// schedule

.schedule
  font-size: 13px
  font-weight: 400
  line-height: 18px
  color: rgba(150, 150, 150, 1)
