@import "@responsive"

.header-container
  &--owner
    padding-left: 16px
    padding-right: 16px
    +responsive(mobile)
      padding-left: 12px

.header-content
  display: grid
  grid-template-columns: min-content 1fr min-content
  align-items: center
  justify-content: space-between
  gap: 6px
  height: var(--h-header)
  min-height: var(--h-header)
  margin: 0 auto
  padding-top: 16px
  padding-bottom: 16px
  z-index: 2
  &--owner
    grid-template-columns: min-content  min-content
    padding-left: 16px
    +responsive(mobile)
      grid-template-columns: auto auto
      padding-left: 0
      padding-right: 3px

  +responsive(mobile)
    grid-template-columns: auto auto
    grid-template-rows: 1fr
    height: initial
    gap: 16px
    padding-top: 10px
    padding-bottom: 16px

.logo
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 16px
  +responsive(mobile)
    gap: 12px

.logo-main-link
  height: 42px
  width: 42px
  display: block
  +responsive(mobile)
    height: 40px
    width: 40px
.logo-main-image
  text-decoration: none
  height: inherit
  width: inherit

.logo-text-link
  display: block
  height: 20px
  width: 100px
  +responsive(mobile)
    width: 85px
    height: 17px
.logo-text-image
  height: inherit
  width: inherit
  transition: color .15s ease
  color: black

.logo-column
  margin-bottom: -4px
  display: flex
  flex-direction: column
  gap: 3px
  max-width: 100px
  +responsive(mobile)
    gap: 1px

.logo-owner
  display: block
  width: 270px
  height: 24px
  margin: 0
  +responsive(mobile)
    width: 224px
    height: 20px

$headerItemsGap: 4px

.header_additional
  display: flex
  justify-content: flex-end
  align-items: center
  gap: $headerItemsGap
  min-width: 206px
  +responsive(mobile)
    min-width: initial
    margin-left: 0
    gap: 6px
    justify-content: flex-end

.back-button
  display: block
  cursor: pointer
  min-width: 116px
  font-size: 13px
  line-height: 32px
  padding: 0 16px
  height: 32px
  text-align: center
  white-space: nowrap
  border-radius: calc(32px / 2)
  background-color: #FFFFFF
  transition: all 0.3s ease-in-out
  margin-right: calc(16px - $headerItemsGap)
  &:hover
    background-color: #F2F2F2
    transition: all 0s ease-in-out
