@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  align-items: center
  height: 133px

.card
  gap: 16px
  position: relative
  width: calc(100vw - 40px)
  overflow: hidden
  background-color: #fff
  border-radius: 16px
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)
  transition: all .8s cubic-bezier(0.38, 0.28, 0.12, 1.30)
  padding: 12px
  height: 133px
  :global(.swiper-slide-active) &
    height: inherit

  :global(.swiper-slide-active) &[data-has-buttons='true']
    padding-bottom: 76px
  :global(.hidden-view) &[data-has-buttons='true']
    padding-bottom: 12px !important
    transition-duration: .3s


.main
  display: flex
  justify-content: space-between
  align-items: flex-start
  gap: 24px
  opacity: 0
  transition: opacity .5s ease
  .wrapper.skeleton &
    opacity: 1
  :global(.swiper-slide-active) &
    opacity: 1
  :global(.hidden-view) &
    transition-duration: .25s
    opacity: 0 !important

.content
  display: flex
  flex-direction: column

.main-footer
  margin-top: 8px
  display: flex
  align-items: center
  gap: 6px

.image
  border-radius: 10px
  object-fit: cover

.metro-list
  display: flex
  flex-wrap: wrap
  gap: 3px 10px

#metro-item span
  font-size: 13px
  line-height: 18px

.chevron
  width: 26px
  height: 26px

.rest-additional-info-wrap
  margin-top: 8px
  display: inline-flex
  gap: 2px
  align-items: center
  vertical-align: center

.rest-tag
  color:  rgba(150, 150, 150, 1)

.price-dot
  position: relative
  top: 0
  width: 2px
  height: 2px
  background-color:  rgba(150, 150, 150, 1)
  opacity: 1
  border-radius: 50%
  margin: 0 4px

.buttons
  position: absolute
  left: 12px
  bottom: 12px
  right: 12px
  display: flex
  gap: 8px
  opacity: 0
  transition: opacity .5s ease
  :global(.swiper-slide-active) &
    opacity: 1
  :global(.hidden-view) &
    opacity: 0 !important
    transition-duration: .3s

.rest-booking-button
  flex: 1

.address
  margin-top: 6px

.price-range
  line-height: 18px
