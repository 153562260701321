@import '@responsive'
@import '@mixins'
@import '@fonts'

.card
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  height: 141px
  gap: 12px
  position: relative
  min-width: calc(100vw - 40px)
  overflow: hidden
  background-color: #fff
  border-radius: 16px
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)
  transition: all .8s cubic-bezier(0.38, 0.28, 0.12, 1.30)
  padding: 12px

  :global(.swiper-slide-active) &
    height: inherit

